html,
body {
  padding: 0;
  margin: 0 auto;
  height: 100%;
  /* max-width: 768px; */
  font-family: Pretendard;
  font-size: 14px;
  color: #000;
  word-break: normal;
  word-wrap: break-word;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}

p,
label {
  margin: 0;
}

input {
  box-sizing: border-box;
  appearance: none; /* 표준 속성 */
  user-select: none;
  /* -webkit-padding-start: 0 !important; */
  -webkit-user-select: text;
  -webkit-appearance: none; /* 웹킷 브라우저용 */
  -moz-appearance: none;
}

textarea {
  resize: none;
  appearance: none; /* 표준 속성 */
  -webkit-appearance: none; /* 웹킷 브라우저용 */
  -moz-appearance: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
}

h2 {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
  }
}

* {
  outline: none;
  font-family: inherit;
  box-sizing: border-box;
}

input:disabled {
  -webkit-text-fill-color: currentColor;
  opacity: 1;
}

/* ::selection {
  background: transparent;
} */

/* 기본 포커스 스타일 제거 */
input:focus {
  outline: none;
  box-shadow: none;
}

/* Autofill 배경색 제거 */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: black;
}
